import { Component, HostListener, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgwWowService } from "ngx-wow";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import { API_URLS } from "./config/app.constants";
import { HttpinterceptorService } from "./shared/services/httpinterceptor.service";
import { SpinnerComponent } from "./shared/spinners/spinner.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [RouterOutlet, SpinnerComponent],
})
export class AppComponent implements OnInit {
  title = "Wellness";
  private wowSubscription: Subscription;

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    event.preventDefault();
    this.closeModal(); // Or call what the dismiss method is
  }
  constructor(
    private wowService: NgwWowService,
    private http: HttpinterceptorService,
    private modalRef: NgbModal
  ) {
    this.wowService.init();
  }

  ngOnInit() {
    this.getGoogleMapKey();

    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        // do whatever you want with revealed element
      }
    );
  }
  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }
  getGoogleMapKey() {
    this.http
      .post(API_URLS.SHOW_CONFIGURATION, {}, false)
      .subscribe((response: any) => {
        const google_map_key = response.pearland_status.google_map_key;
        localStorage.setItem("google_map_key", google_map_key);
      });
  }
  closeModal() {
    this.modalRef.dismissAll();
    swal.close();
  }
}
