<section class="login-module">
  <div class="container login-container p-0">
    <div class="row">
      <div class="col-lg-5 col-xl-4 left-sec pe-lg-0 d-none d-lg-block">
        <div class="h-100 inner-box">
          <div class="top-box px-4 pt-4 bg-primary text-center">
            <img [src]="'assets/images/login/login-dog.png'" alt="" class="img-fluid" />
          </div>
          <div class="bottom-box p-5">
            <ul>
              <li>
                <strong class="d-block">Wish to Board My Pet</strong>
                Yes we are having boarding service for your lovable pet.
              </li>
              <li>
                <strong class="d-block">Wish to Opt for Wellness Plans</strong>
                Our awesome wellness plans and our extra add-ons will make your
                requirement complete.
              </li>
              <li>
                <strong class="d-block">Wish to Groom My Pet</strong>
                Our experienced Groomers will change the complete look of your
                pet.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-8 right-sec ps-lg-0">
        <div class="form-box h-100 d-flex justify-content-center align-items-center p-5 login-modal">
          @if(fromPage=='login'){
          <ng-container *ngTemplateOutlet="loginForm"> </ng-container>
          } @else if (fromPage=='signup') {
          <ng-container *ngTemplateOutlet="registrationForm"> </ng-container>
          } @else if (fromPage=='forgetPssword') {
          <ng-container *ngTemplateOutlet="forgetPassForm"> </ng-container>
          }
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #loginForm>
  <div class="form-inner-box">
    <button type="button" class="close btn-close position-absolute top-0 end-0 m-4" aria-label="Close"
      (click)="activeModal.dismissAll()">
    </button>
    <h4 class="text-capitalize position-relative pt-3">
      Welcome to
      <strong class="text-info">the safari Wellness</strong>
      login to continue
    </h4>
    <p>
      <small class="text-capitalize"> Explore our wellness plans. </small>
    </p>
    <form [formGroup]="loginUser" (ngSubmit)="loginService.login(loginUser)" [autocomplete]="false" novalidate>
      <input type="text" trim="blur" formControlName="email" placeholder="Email" class="form-control mb-3" />

      @if(( loginUser?.get('email')?.touched || loginUser?.get('email')?.dirty
      || loginPageSubmitted ) && !loginUser?.get('email')?.valid &&
      !loginUser?.get('email')?.pending){

      <span class="text-danger error">
        @if(loginUser?.get('email')?.errors.required){ Email is required }
        @if(loginUser?.get('email')?.errors.pattern){ Please Fill Proper Email }
      </span>
      }
      <div class="pwd-box">
        <input [type]="showHidePassword?'text':'password'" formControlName="password" placeholder="Password"
          class="form-control mb-3" />
        <span class="cursor-pointer"><i (click)="passwordShowHide()" class="fa-solid"
            [ngClass]="showHidePassword?'fa-eye-slash':'fa-eye'"></i></span>
      </div>
      @if((loginUser?.get('password')?.touched ||
      loginUser?.get('password')?.dirty || loginPageSubmitted ) &&
      !loginUser?.get('password')?.valid &&
      !loginUser?.get('password')?.pending){

      <span class="text-danger error">
        @if(loginUser?.get('password')?.errors.required){ Password is required }
        @if(loginUser?.get('password')?.errors.minlength){ Minimum 6 characters
        required } @if(loginUser?.get('password')?.errors.maxlength){ Maximum 20
        characters allowed }
      </span>
      }

      <div class="d-flex justify-content-between align-items-center flex-column flex-xl-row">
        <a class="link-info link cursor-pointer" (click)="toggleLoginSignupPage('forgetPssword')">Forgot Password?</a>
        <button class="btn btn-success mt-3 mt-xl-0 cursor-pointer" type="submit">
          Sign In
        </button>
      </div>
      <div class="text-center lined-box">
        Have Not account yet?
        <a (click)="toggleLoginSignupPage('login')" class="link-info link cursor-pointer">
          Create Account
        </a>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #registrationForm>
  <div class="form-inner-box w-100">
    <button type="button" class="close btn-close position-absolute top-0 end-0 m-4" aria-label="Close"
      (click)="activeModal.dismissAll()">
    </button>
    <h4 class="text-capitalize position-relative pt-3 text-primary">
      Glade to See you!
    </h4>
    <p class="link-txt text-primary">
      Create an account on
      <span class="text-secondary text-capitalize">wellness safari</span>
    </p>
    <form [formGroup]="addUser" (ngSubmit)="loginService.saveUserData(addUser)">
      <div class="row">
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="first_name" class="form-control mb-3"
            placeholder="First Name" />

          @if((addUser?.get('first_name')?.touched ||
          addUser?.get('first_name')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('first_name')?.valid &&
          !addUser?.get('first_name')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('first_name')?.errors.required){ Full Name is
            required }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="last_name" class="form-control mb-3"
            placeholder="Last Name" />

          @if((addUser?.get('last_name')?.touched ||
          addUser?.get('last_name')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('last_name')?.valid &&
          !addUser?.get('last_name')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('last_name')?.errors.required){ Last Name is
            required }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="email" class="form-control mb-3" placeholder="Email" />

          @if((addUser?.get('email')?.touched || addUser?.get('email')?.dirty ||
          registrationPageSubmitted ) && !addUser?.get('email')?.valid &&
          !addUser?.get('email')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('email')?.errors.required){ Email is required }
            @if(addUser?.get('email')?.errors.pattern){ Please Fill Proper Email
            }
          </span>
          }
        </div>

        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="hear_about_us" class="form-control mb-3"
            placeholder="How did you here about us?" />
        </div>
        <div class="col-xl-6 px-2">
          <div class="pwd-box">
            <input [type]="showHidePassword?'text':'password'" formControlName="password" class="form-control mb-3"
              placeholder="Password" />
            <span class="cursor-pointer"><i (click)="passwordShowHide()" class="fa-solid"
                [ngClass]="showHidePassword?'fa-eye-slash':'fa-eye'"></i></span>
          </div>
          @if((addUser?.get('password')?.touched ||
          addUser?.get('password')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('password')?.valid &&
          !addUser?.get('password')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('password')?.errors.required){ Password is
            required } @if(addUser?.get('password')?.errors.minlength){ Minimum 6
            characters required }
            @if(addUser?.get('password')?.errors.maxlength){ Maximum 20
            characters allowed }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <div class="pwd-box">
            <input [type]="showHideConfirmPassword?'text':'password'" formControlName="password_confirmation"
              class="form-control mb-3" placeholder="Confirm Password" />
            <span class="cursor-pointer"><i (click)="confirmPasswordShowHide()" class="fa-solid"
                [ngClass]="showHideConfirmPassword?'fa-eye-slash':'fa-eye'"></i></span>
          </div>
          @if((addUser?.get('password_confirmation')?.touched ||
          addUser?.get('password_confirmation')?.dirty ||
          registrationPageSubmitted ) &&
          !addUser?.get('password_confirmation')?.valid &&
          !addUser?.get('password_confirmation')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('password_confirmation')?.errors.required){ Confirm
            Password is required }
            @if(addUser?.get('password_confirmation')?.errors.mustMatch){
            Confirm Password Does not match With Password }
          </span>
          }
        </div>

        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="phone_no" class="form-control mb-3"
            placeholder="Mobile Number" />

          @if(( addUser?.get('phone_no')?.touched ||
          addUser?.get('phone_no')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('phone_no')?.valid &&
          !addUser?.get('phone_no')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('phone_no')?.errors.required){ Mobile Number is
            required } @if(addUser?.get('phone_no')?.errors.pattern){ Please
            enter numerals only (no-dashes) }
            @if(addUser?.get('phone_no')?.errors.minlength){ Minimum 6 digits
            required } @if(addUser?.get('phone_no')?.errors.maxlength){ Maximum
            15 digits allowed }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="home_phone" class="form-control mb-3"
            placeholder="Home Phone Number" />

          @if(( addUser?.get('home_phone')?.touched ||
          addUser?.get('home_phone')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('home_phone')?.valid &&
          !addUser?.get('home_phone')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('home_phone')?.errors.pattern){ Please enter
            numerals only (no-dashes) }
          </span>
          }
        </div>

        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="country" class="form-control mb-3" placeholder="Country" />
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="state" class="form-control mb-3" placeholder="State" />

          @if((addUser?.get('state')?.touched || addUser?.get('state')?.dirty ||
          registrationPageSubmitted ) && !addUser?.get('state')?.valid &&
          !addUser?.get('state')?.pending){

          <span class="text-danger error">
            @if(addUser?.get('state')?.errors.required){ State is required }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="city" id="" class="form-control mb-3" placeholder="City" />

          @if((addUser?.get('city')?.touched || addUser?.get('city')?.dirty ||
          registrationPageSubmitted ) && !addUser?.get('city')?.valid &&
          !addUser?.get('city')?.pending){
          <span class="text-danger error">
            @if(addUser?.get('city')?.errors.required){ City is required }
          </span>
          }
        </div>

        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="zipcode" class="form-control mb-3" placeholder="Zip Code" />

          @if((addUser?.get('zipcode')?.touched ||
          addUser?.get('zipcode')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('zipcode')?.valid && !addUser?.get('zipcode')?.pending){
          <span class="text-danger error">
            @if(addUser?.get('zipcode')?.errors.required){ Zipcode is required }
            @if(addUser?.get('zipcode')?.errors.pattern){ Zipcode should be a
            number } @if(addUser?.get('zipcode')?.errors.minlength){ Minimum 5
            digits required } @if(addUser?.get('zipcode')?.errors.maxlength){
            Maximum 9 digits allowed }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <textarea trim="blur" formControlName="address" rows="1" class="form-control mb-3" placeholder="Address">
          </textarea>

          @if((addUser?.get('address')?.touched ||
          addUser?.get('address')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('address')?.valid && !addUser?.get('address')?.pending){
          <span class="text-danger error">
            @if(addUser?.get('address')?.errors.required){ Address is required }
          </span>
          }
        </div>
        <div class="col-xl-6 px-2">
          <input type="text" trim="blur" formControlName="tdl" class="form-control mb-3" placeholder="TDL Number" />

          @if((addUser?.get('tdl')?.touched || addUser?.get('tdl')?.dirty ||
          registrationPageSubmitted ) && !addUser?.get('tdl')?.valid &&
          !addUser?.get('tdl')?.pending){
          <span class="text-danger error">
            @if(addUser?.get('tdl')?.errors.pattern){ TDL should be a number }
          </span>
          }
        </div>

        <div class="col-xl-12 px-2 mb-2">
          <div class="link-txt text-primary d-flex">
            <input type="checkbox" class="form-check-input mt-0" id="term_condition" formControlName="term_condition" />
            <label class="small-text" class="form-check-label ms-2">By registering, I agree to
              <a href="https://www.safarivet.com/" class="link-info link cursor-pointer" target="_blank">Terms and
                Conditions</a></label>
          </div>
          @if((addUser?.get('term_condition')?.touched ||
          addUser?.get('term_condition')?.dirty || registrationPageSubmitted ) &&
          !addUser?.get('term_condition')?.valid && !addUser?.get('term_condition')?.pending){
          <span class="text-danger error mt-3">
            @if(addUser?.get('term_condition')?.errors.required){ Accept terms and
            conditions to continue }
          </span>
          }
        </div>
        <div class="d-flex justify-content-between align-items-center flex-column flex-xl-row">
          <div class="link-txt text-primary">
            Already have an account?
            <a class="link-info link cursor-pointer" (click)="toggleLoginSignupPage('signup')">Sign In</a>
          </div>
          <button class="btn btn-success mt-3 mt-xl-0" type="submit">
            Register Now
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #forgetPassForm>
  <div class="form-inner-box text-primary">
    <button type="button" class="close btn-close position-absolute top-0 end-0 m-4" aria-label="Close"
      (click)="activeModal.dismissAll()">
    </button>
    <h4 class="text-capitalize position-relative pt-3 text-primary">
      Forgot Password
    </h4>
    <p>
      <small class="text-capitalize">
        No worries, we will send you instructions on how to reset your password
        by email.
      </small>
    </p>
    <form [formGroup]="userDetails" (ngSubmit)="forgotUser()">
      <input type="text" trim="blur" formControlName="email" class="form-control mb-3" placeholder="Email" />

      @if((userDetails?.get('email')?.touched ||
      userDetails?.get('email')?.dirty || forgetPageSubmitted ) &&
      !userDetails?.get('email')?.valid && !userDetails?.get('email')?.pending){
      <span class="text-danger error">
        @if(userDetails?.get('email')?.errors.required){ Email is required }
        @if(userDetails?.get('email')?.errors.pattern){ Please Fill Proper Email
        }
      </span>
      }

      <div class="d-flex justify-content-md-end justify-content-center align-items-center">
        <button class="btn btn-outline-success me-3" (click)="cancelForgotPassword()">
          Cancel
        </button>
        <button class="btn btn-success" type="submit">Submit</button>
      </div>
    </form>
  </div>
</ng-template>