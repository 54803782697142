"use strict";

import { API_PREFIX, IMG_PREFIX } from "src/environments/environment";

const API_LINK = API_PREFIX;
const IMG_LINK = IMG_PREFIX;
const ADMIN_MODULE = API_LINK + "admin";
const USER_MODULE = API_LINK + "web";
export const MAX_FILE_SIZE = "2097152";

export const SELECT_SEX = [
  "male",
  "female",
  "male neutered",
  "female spayed",
  "unknown",
];
export const PET_SIZES = [
  { title: "small", id: "small_price" },
  { title: "medium", id: "medium_price" },
  { title: "large", id: "large_price" },
  { title: "giant", id: "giant_price" },
];
export const DECIMAL_NUMBER_PATTERN = "^[0-9]+(.?[0-9]+)*$";
export const WHOLE_NUMBER_PATTERN = "^[0-9]*$";
export const AGE_AND_WEIGHT_PATTERN = "^[0-9]+(.?[0-9]+)*$";
export const EMAIL_PATTERN =
  /^([!#-\'*+\/-9=?A-Z^-~\\\\-]{1,64}(\.[!#-\'*+\/-9=?A-Z^-~\\\\-]{1,64})*|"([\]!#-[^-~\ \t\@\\\\]|(\\[\t\ -~]))+")@([0-9A-Z]([0-9A-Z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Z]([0-9A-Z-]{0,61}[0-9A-Za-z])?))+$/i;
export const FIRST_TEN_NUMBERS = Array(10)
  .fill(null)
  .map((x, i) => i + 1);
export const WHOLE_MONTH_DATES = Array(28)
  .fill(null)
  .map((x, i) => i + 1);
export const TIME_ZONE = "US/Central";
export const SLIDER_CONFIGURATION = {
  PLAN: {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  },
};
export const SCROLLBARS_OPTIONS = {
  axis: "y",
  theme: "custom-scroll-theme",
  autoHideScrollbar: false,
};
export const SINGLE_SLICK_SLIDER_CONFIG = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};
export const DETAIL_PAGE_SLIDE_CONFIG = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: false,
  infinite: true,
  asNavFor: ".slider-nav",
};

export const HEALTHMAP_IMAGES = {
  petTopImages: [
    {
      dog: [
        "assets/images/health-map/top-group/dog/1.png",
        "assets/images/health-map/top-group/dog/2.png",
        "assets/images/health-map/top-group/dog/3.png",
        "assets/images/health-map/top-group/dog/4.png",
      ],
      bird: [
        "assets/images/health-map/top-group/birds/1.png",
        "assets/images/health-map/top-group/birds/2.png",
        "assets/images/health-map/top-group/birds/3.png",
        "assets/images/health-map/top-group/birds/4.png",
      ],
      reptile: [
        "assets/images/health-map/top-group/reptile/1.png",
        "assets/images/health-map/top-group/reptile/2.png",
        "assets/images/health-map/top-group/reptile/3.png",
        "assets/images/health-map/top-group/reptile/4.png",
      ],
      primates: [
        "assets/images/health-map/top-group/primates/1.png",
        "assets/images/health-map/top-group/primates/2.png",
        "assets/images/health-map/top-group/primates/3.png",
        "assets/images/health-map/top-group/primates/4.png",
      ],
      mammal: [
        "assets/images/health-map/top-group/small-mammal/1.png",
        "assets/images/health-map/top-group/small-mammal/2.png",
        "assets/images/health-map/top-group/small-mammal/3.png",
        "assets/images/health-map/top-group/small-mammal/4.png",
      ],
      cat: [
        "assets/images/health-map/top-group/cat/1.png",
        "assets/images/health-map/top-group/cat/2.png",
        "assets/images/health-map/top-group/cat/3.png",
        "assets/images/health-map/top-group/cat/4.png",
      ],
      hamster: [
        "assets/images/health-map/top-group/hamster/1.png",
        "assets/images/health-map/top-group/hamster/2.png",
        "assets/images/health-map/top-group/hamster/3.png",
        "assets/images/health-map/top-group/hamster/4.png",
      ],
      ferret: [
        "assets/images/health-map/top-group/ferret/1.png",
        "assets/images/health-map/top-group/ferret/2.png",
        "assets/images/health-map/top-group/ferret/3.png",
        "assets/images/health-map/top-group/ferret/4.png",
      ],
      guinea_pig: [
        "assets/images/health-map/top-group/guinea-pig/1.png",
        "assets/images/health-map/top-group/guinea-pig/2.png",
        "assets/images/health-map/top-group/guinea-pig/3.png",
        "assets/images/health-map/top-group/guinea-pig/4.png",
      ],
      hedgehog: [
        "assets/images/health-map/top-group/hedgehog/1.png",
        "assets/images/health-map/top-group/hedgehog/2.png",
        "assets/images/health-map/top-group/hedgehog/3.png",
        "assets/images/health-map/top-group/hedgehog/4.png",
      ],
      pot_bellied_pig: [
        "assets/images/health-map/top-group/pot-bellied-pig/1.png",
        "assets/images/health-map/top-group/pot-bellied-pig/2.png",
        "assets/images/health-map/top-group/pot-bellied-pig/3.png",
        "assets/images/health-map/top-group/pot-bellied-pig/4.png",
      ],
      prairie_dog: [
        "assets/images/health-map/top-group/prairie-dog/1.png",
        "assets/images/health-map/top-group/prairie-dog/2.png",
        "assets/images/health-map/top-group/prairie-dog/3.png",
        "assets/images/health-map/top-group/prairie-dog/4.png",
      ],
      rabbit: [
        "assets/images/health-map/top-group/rabbit/1.png",
        "assets/images/health-map/top-group/rabbit/2.png",
        "assets/images/health-map/top-group/rabbit/3.png",
        "assets/images/health-map/top-group/rabbit/4.png",
      ],
      sugar_glider: [
        "assets/images/health-map/top-group/sugar-glider/1.png",
        "assets/images/health-map/top-group/sugar-glider/2.png",
        "assets/images/health-map/top-group/sugar-glider/3.png",
        "assets/images/health-map/top-group/sugar-glider/4.png",
      ],
      chinchilla: [
        "assets/images/health-map/top-group/chinchilla/1.png",
        "assets/images/health-map/top-group/chinchilla/2.png",
        "assets/images/health-map/top-group/chinchilla/3.png",
        "assets/images/health-map/top-group/chinchilla/4.png",
      ],
      rat: [
        "assets/images/health-map/top-group/rat/1.png",
        "assets/images/health-map/top-group/rat/2.png",
        "assets/images/health-map/top-group/rat/3.png",
        "assets/images/health-map/top-group/rat/4.png",
      ],
    },
  ],
  petBottomImage: [
    {
      dog: [
        "assets/images/health-map/bottom-group/dog/1.png",
        "assets/images/health-map/bottom-group/dog/2.png",
        "assets/images/health-map/bottom-group/dog/3.png",
      ],
      bird: [
        "assets/images/health-map/bottom-group/birds/1.png",
        "assets/images/health-map/bottom-group/birds/2.png",
        "assets/images/health-map/bottom-group/birds/3.png",
      ],
      reptile: [
        "assets/images/health-map/bottom-group/reptile/1.png",
        "assets/images/health-map/bottom-group/reptile/2.png",
        "assets/images/health-map/bottom-group/reptile/3.png",
      ],
      primates: [
        "assets/images/health-map/bottom-group/primates/1.png",
        "assets/images/health-map/bottom-group/primates/2.png",
        "assets/images/health-map/bottom-group/primates/3.png",
      ],
      mammal: [
        "assets/images/health-map/bottom-group/small-mammal/1.png",
        "assets/images/health-map/bottom-group/small-mammal/2.png",
        "assets/images/health-map/bottom-group/small-mammal/3.png",
      ],
      cat: [
        "assets/images/health-map/bottom-group/cat/1.png",
        "assets/images/health-map/bottom-group/cat/2.png",
        "assets/images/health-map/bottom-group/cat/3.png",
      ],
      hamster: [
        "assets/images/health-map/bottom-group/hamster/1.png",
        "assets/images/health-map/bottom-group/hamster/2.png",
        "assets/images/health-map/bottom-group/hamster/3.png",
      ],
      ferret: [
        "assets/images/health-map/bottom-group/ferret/1.png",
        "assets/images/health-map/bottom-group/ferret/2.png",
        "assets/images/health-map/bottom-group/ferret/3.png",
      ],
      guinea_pig: [
        "assets/images/health-map/bottom-group/guinea-pig/1.png",
        "assets/images/health-map/bottom-group/guinea-pig/2.png",
        "assets/images/health-map/bottom-group/guinea-pig/3.png",
      ],
      hedgehog: [
        "assets/images/health-map/bottom-group/hedgehog/1.png",
        "assets/images/health-map/bottom-group/hedgehog/2.png",
        "assets/images/health-map/bottom-group/hedgehog/3.png",
      ],
      pot_bellied_pig: [
        "assets/images/health-map/bottom-group/pot Bellied pig/1.png",
        "assets/images/health-map/bottom-group/pot Bellied pig/2.png",
        "assets/images/health-map/bottom-group/pot Bellied pig/3.png",
      ],
      prairie_dog: [
        "assets/images/health-map/bottom-group/prairie-dog/1.png",
        "assets/images/health-map/bottom-group/prairie-dog/2.png",
        "assets/images/health-map/bottom-group/prairie-dog/3.png",
      ],
      rabbit: [
        "assets/images/health-map/bottom-group/rabbit/1.png",
        "assets/images/health-map/bottom-group/rabbit/2.png",
        "assets/images/health-map/bottom-group/rabbit/3.png",
      ],
      sugar_glider: [
        "assets/images/health-map/bottom-group/sugar-glider/1.png",
        "assets/images/health-map/bottom-group/sugar-glider/2.png",
        "assets/images/health-map/bottom-group/sugar-glider/3.png",
      ],
      chinchilla: [
        "assets/images/health-map/bottom-group/chinchilla/1.png",
        "assets/images/health-map/bottom-group/chinchilla/2.png",
        "assets/images/health-map/bottom-group/chinchilla/3.png",
      ],
      rat: [
        "assets/images/health-map/bottom-group/rat/1.png",
        "assets/images/health-map/bottom-group/rat/2.png",
        "assets/images/health-map/bottom-group/rat/3.png",
      ],
    },
  ],
  progrebarImages: [
    "assets/images/health-map/percentage-image/1.svg",
    "assets/images/health-map/percentage-image/2.svg",
    "assets/images/health-map/percentage-image/3.svg",
    "assets/images/health-map/percentage-image/4.svg",
    "assets/images/health-map/percentage-image/5.svg",
  ],
};

export const CABIN_THUMB_SLIDE_CONFIG = {
  slidesToShow: 4,
  slidesToScroll: 1,
  asNavFor: ".slider-main",
  dots: false,
  arrows: true,
  // centerMode: true,
  infinite: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
export const GROOMER_TIP_VALUES = [
  { title: "$10", value: 10 },
  { title: "$20", value: 20 },
  { title: "$30", value: 30 },
  { title: "Custom", value: 0 },
];
export const BOX_COLOR_CLASS = [
  "b-green-color",
  "b-purple-color",
  "b-sky-color",
  "b-parrot-color",
  "b-purplish-color",
  "b-regent-grey-color",
  "b-matte-green",
  "b-red-color",
  "b-lightblue-color",
  "",
];

export const MENU_ITEMS = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: "assets/images/icon/dashboard.svg",
  },
  {
    title: "My Orders",
    route: "/orders",
    icon: "assets/images/icon/shopping-cart.svg",
  },
  {
    title: "My Subscription",
    route: "/subscription/list",
    icon: "assets/images/icon/subscription.svg",
  },
  {
    title: "My Bookings",
    route: "/booking/list",
    icon: "assets/images/icon/calendar-date.svg",
  },
  {
    title: "Grooming Bookings",
    route: "/grooming/list",
    icon: "assets/images/icon/bath-tub.svg",
  },
  {
    title: "Saved Bookings",
    route: "/saved_bookings",
    icon: "assets/images/icon/save.svg",
  },
  {
    title: "My Wishlist",
    route: "/wishlist",
    icon: "assets/images/icon/heart.svg",
  },
  {
    title: "Manage Cards",
    route: "/cards",
    icon: "assets/images/icon/credit-card.svg",
  },
  {
    title: "Manage Profile",
    route: "/profile/edit",
    icon: "assets/images/icon/user.svg",
  },
  {
    title: "My Pets",
    route: "/pets",
    icon: "assets/images/icon/pawprint.svg",
  },
];

export const BACKGROUND_COUNT_HEALTH_MAP = 5;

export const PAGINATION = 10;
export const DATEFORMATE = "MM/dd/yyyy";
export const DATE_FORMAT_WITH_TIME = "MM/dd/yyyy hh:mm a";
export const TIME_FORMATE = "hh:mm a";
export const MOMENT_FORMAT_START_YEAR = "YYYY/MM/DD";
export const MOMENT_FORMAT_START_DATE = "DD/MM/YYYY";
export const P_CALENDAR_DATE_FORMAT = "mm/dd/yy";
export const ORDER_DATE_FORMAT = "d MMMM y";
export const AVATAR_FG_COLOR = "#022A45";
export const AVATAR_BG_COLOR = "#C2D840";
export const FILE_PATH = IMG_LINK + "/";
export const FILE_PATH_PLAN = IMG_LINK + "/plan/";
export const FILE_PATH_CAGE = IMG_LINK + "/cage/";
export const FILE_PATH_PET = IMG_LINK + "/pet/";
export const SLIDER_PATH = IMG_LINK + "/booking/slider/";
export const FILE_PATH_TESTIMONIAL = IMG_LINK + "/web_content/testimonial/";
export const FILE_PATH_MENU = IMG_LINK + "/web_content/menu-items/";

export const FILE_PATH_OUTPOST_PROFILE = IMG_LINK + "/web_content/outpost/";
export const BOARDING_COVER_IMAGE = IMG_LINK + "/web_content/cover_image/";

export const PRODUCT_PATH = IMG_LINK + "/web_content/products/";
export const DEFAULT_IMAGE_PATH = "assets/images/";
export const HEALTH_MAP_PATH = "assets/images/helath-map-report/";
export const DEFAULT_HEALTH_MAP_IMAGE_PATH = HEALTH_MAP_PATH + "default_image/";
export const DOG_HEALTH_MAP_IMAGE_PATH = HEALTH_MAP_PATH + "dog_image/";
export const CAT_HEALTH_MAP_IMAGE_PATH = HEALTH_MAP_PATH + "cat_image/";

export const RECOVERY_PAYMENT_OPTIONS = [
  { name: "Full Payment", value: "full_payment" },
  { name: "Three Months Installments", value: "three_month_installment" },
  { name: "Six Months Installments", value: "six_month_installment" },
];

export const DEFAULT_INWARD_TIME_NUMBER = 6;
export const DEFAULT_OUTWARD_TIME_NUMBER = 20;

export const API_URLS = {
  GET_OUT_POST_LIST: USER_MODULE + "/outpost/list",
  PRINT_HEALTH_MAP: USER_MODULE + "/health-map/print",
  GET_MENUS: USER_MODULE + "/menu-items",
  ADD_USER: USER_MODULE + "/register",
  USER_LOGIN: USER_MODULE + "/login",
  UPLOAD_PLAN_IMAGE: USER_MODULE + "/upload-image",
  UPLOAD_ATTACHMENT: USER_MODULE + "/upload-attachment",
  FILE_PATH_USER_PROFILE: IMG_LINK + "/web_content/admin_user/avatar/",
  PRODUCT_FILE_UPLOAD_PATH: IMG_LINK + "/web_content/order_product",
  PET_FILE_UPLOAD_PATH: IMG_LINK + "/web_content/pet_documents",
  PET_BANNER_IMAGE: IMG_LINK + "/web_content/pet_type/",
  TEMP_PATH_PLAN: IMG_LINK + "/tmp/",
  GET_PLAN: USER_MODULE + "/plan",
  GET_PLAN_DETAILS: USER_MODULE + "/plans",
  UPDATE_CART: USER_MODULE + "/cart/update",
  REMOVE_ITEM_CART: USER_MODULE + "/cart/remove",
  GET_CART: USER_MODULE + "/cart",
  GET_PLANS: USER_MODULE + "/plan-list",
  GET_RECAPTCHA_KEY: USER_MODULE + "/google-captcha-key",
  FORGOT_USER: USER_MODULE + "/forgot-password",
  RESET_USER: USER_MODULE + "/reset-password",
  ADD_CARD: USER_MODULE + "/checkout/addcard",
  GET_CARD: USER_MODULE + "/cards",
  CHECKOUT: USER_MODULE + "/checkout/order",
  GET_SUBSCRIPTION: USER_MODULE + "/subscriptions",
  GET_SUBSCRIPTION_DETAILS: USER_MODULE + "/subscription/",
  SUBSCRIPTION_HISTORY: USER_MODULE + "/usage-history",
  SERVICE_SUBSCRIPTION_HISTORY: USER_MODULE + "/service/usage-history",
  UPGRADEPLAN: USER_MODULE + "/plan-level/upgrade-list",
  UPGRADE_DOWNGRADE_LIST: USER_MODULE + "/upgrade-downgrade-list",
  DOWNGRADEPLAN: USER_MODULE + "/plan-level/downgrade-list",
  PLANLEVELDETAILS: USER_MODULE + "/plan-level",
  PLANCHANGE: USER_MODULE + "/plan-level/change",
  CANCELSUBSCRIPTION: USER_MODULE + "/cancel-subscription",
  REMAININGPAYMENT: USER_MODULE + "/remaning-payment",
  REMOVE_CARD: USER_MODULE + "/checkout/removecard",
  CHANGE_PLAN: USER_MODULE + "/plan/change",
  CHANGE_REQUEST: USER_MODULE + "/plan-level/change-request",
  CHANGE_SUBSCRIPTION_CYCLE: USER_MODULE + "/change-subscription-cycle",
  CHANGE_CARD: USER_MODULE + "/subscription/card_update",
  AUTO_RENEW: USER_MODULE + "/subscription/auto_renew",
  PAYMENT_HISTORY: USER_MODULE + "/user-subscription-payment",
  GET_NEXT_PAYMENT_INFO: USER_MODULE + "/get-next-payment",
  STOP_ADDON_SERVICES: USER_MODULE + "/stop/addon/subscription/",
  STOP_EXTRA_SERVICES_SERVICES:
    USER_MODULE + "/stop/extra_services/subscription/",

  START_ADDON_SERVICES_LIST: USER_MODULE + "/subscribed/addons",
  START_EXTRA_SERVICES_LIST: USER_MODULE + "/subscribed/extra_services",

  START_ADDON_SERVICES: USER_MODULE + "/new/addon/subscription",
  START_EXTRA_SERVICES_SERVICES:
    USER_MODULE + "/new/extra_services/subscription",

  GET_USER_INFORMATION: USER_MODULE + "/user-data",
  UPDATE_PROFILE: USER_MODULE + "/my-profile",
  CHANGE_PASSWORD: USER_MODULE + "/change-password",
  RAISE_REQUEST: USER_MODULE + "/plan/change-request",
  STOPPED_SUBSCRIPTION: USER_MODULE + "/stopped-subscription",
  PREVIOUS_SUBSCRIPTION: USER_MODULE + "/previous-plan",
  GET_REQUEST_LIST: USER_MODULE + "/plan-change-requests",
  PET_DETAILS: USER_MODULE + "/pet/",
  PET_DETAILS_UPDATE: USER_MODULE + "/pet/update/",
  BACK_TO_ADMIN_DASHBOARD: USER_MODULE + "/switch-back-frontend",
  LOGOUT: USER_MODULE + "/logout",
  GET_ORDERS: USER_MODULE + "/orders",
  ORDER_DETAILS: USER_MODULE + "/order",
  ORDER_PAYMENT: USER_MODULE + "/order-paymet",
  GET_FAILED_AMOUNT: USER_MODULE + "/get-failed-payment/",
  PAY_FAILED_PATMENT: USER_MODULE + "/pay-faild-payment",

  GET_CAGE_TYPE: USER_MODULE + "/cage-types",
  GET_CAGE_TYPE_DETAILS: USER_MODULE + "/cage-type",
  CHECK_AVAILABILITY: USER_MODULE + "/check-availability",
  GET_USER_PETS: USER_MODULE + "/pets",
  BOOKING_SETTING: USER_MODULE + "/setting/booking",
  BOOK_CAGE: USER_MODULE + "/book-cage",
  GET_BOOKINGS: USER_MODULE + "/boarding-list",
  GET_BOOKING_DETAILS: USER_MODULE + "/booking",
  CANCEL_BOOKING_REQUEST: USER_MODULE + "/cancel-booking-request/raise",
  PET_EXTRA_PRICE: USER_MODULE + "/pet-extra-price",
  BOOKING_PRICE: USER_MODULE + "/booking-price",
  PET_BOOKING_DETAIL: USER_MODULE + "/pet-booking-detail/",
  PET_TYPES: USER_MODULE + "/pet-types",
  CHECK_CAGE_AVAILABILITY: USER_MODULE + "/cage-type/available",

  GET_RECENT_ORDERS: USER_MODULE + "/get-recent-orders",
  GET_RECENT_PAYMENTS: USER_MODULE + "/get-recent-payments",
  GET_USER_DASHBOARD_COUNT: USER_MODULE + "/dashboard-counter",
  GET_REMINDERS_SUBSCRIPTION: USER_MODULE + "/get-next-payment",
  GET_REMINDERS_BOOKINGS: USER_MODULE + "/get-next-bookings",
  GET_BOARDING_ADDONS: USER_MODULE + "/boarding-addons/list",
  SAVE_BOOKING_DETAILS: USER_MODULE + "/saved-booking/create",
  GET_SAVED_BOOKINGS_LIST: USER_MODULE + "/saved-bookings",
  GET_SAVED_BOOKING_DETAILS: USER_MODULE + "/saved-booking/",
  GET_BOARDING_SLIDER_LIST: USER_MODULE + "/boarding-slider",
  GET_TESTIMONIALS_LIST: USER_MODULE + "/testimonials",
  CONTACT_US_SUBMIT: USER_MODULE + "/contact-us",
  ADD_SUBSCRIBER: USER_MODULE + "/subscriber/add",
  ADD_PET_FOR_BOOKING: USER_MODULE + "/pet/add",
  CHANGE_BOOKING_DATE: USER_MODULE + "/change-booking-date",

  GET_ALL_NOTIFICATIONS: USER_MODULE + "/notifications",
  // READ_ALL_NOTIFICATIONS: USER_MODULE + '/read-all-notification',
  // READ_NOTIFICATION: USER_MODULE + '/read-notigication/',

  GET_ACTIVE_BOOKING_MEDICATION: USER_MODULE + "/active-booking/medication",
  GET_MEDICATION_DETAILS: USER_MODULE + "/get-medication-detail",
  PRINT_MEDICATION_DETAILS: USER_MODULE + "/print-medication-sheet/",
  AUDIT_HISTORY: USER_MODULE + "/audit-history",
  AUDIT_HISTORY_DETAILS: USER_MODULE + "/audit-history/",
  SENT_BOOKING_REQUEST: USER_MODULE + "/sent-booking/request",
  SHOW_CONFIGURATION: USER_MODULE + "/site-configuration",
  BOARDING_MENU_IMAGE: USER_MODULE + "/boarding-menu-image",
  BOARDING_DISCOUNT_FOR_SLIDER: USER_MODULE + "/boarding-discount-for-slider",
  MAXIMUM_NUM_OF_PETS: USER_MODULE + "/maximum-no-of-pets",

  GET_GROOMING_PET_TYPE_LIST: USER_MODULE + "/pet-types/list",
  GET_GROOMING_PET_BREED_LIST: USER_MODULE + "/breeds",
  GET_GROOMING_PLAN_LIST: USER_MODULE + "/grooming-plans",
  GET_GROOMER_LIST: USER_MODULE + "/search-groomer",
  CREATE_NEW_GROOMING: USER_MODULE + "/book-grooming",

  GET_GROOMING_BOOKINGS: USER_MODULE + "/groomings",
  GET_GROOMING_DETAILS: USER_MODULE + "/grooming/", // pass {grooming_id} for get details
  GET_GROOMING_PAYMENT_DETAILS: USER_MODULE + "/grooming/payments/", // pass {grooming_id} for get payment details
  ADD_MANDATORY_PRODUCTS: USER_MODULE + "/mandatory_products/add",

  GET_PREVIOUS_GROOMER_DETAILS: USER_MODULE + "/find-next-availability",
  GET_PET_SIZE: USER_MODULE + "/pet-sizes",
  GET_GROOMING_PRICE_CALCULATION: USER_MODULE + "/get-grooming-price",
  MANDATORY_PRODUCTS_LIST: USER_MODULE + "/mandatory_products/list",

  GET_PRODUCT_LIST: USER_MODULE + "/products",
  GET_PRODUCT_DETAIL: USER_MODULE + "/product/",
  GET_PRODUCT_FILTER_DATA: USER_MODULE + "/product-filter-data",
  GET_PURCHASED_PRODUCT_DETAIL: USER_MODULE + "/order-product/",

  ADD_PRODUCT_TO_WISHLIST: USER_MODULE + "/add-to-wishlists",
  REMOVE_PRODUCT_FROM_WISHLIST: USER_MODULE + "/remove-from-wishlist",
  GET_WISHLIST: USER_MODULE + "/wishlists",

  GET_REVIEW_QUESTION: USER_MODULE + "/get-review-quetion",
  SUBMIT_REVIEW: USER_MODULE + "/submit-review",
  GET_SERVICE_REVIEW: USER_MODULE + "/get-service-review",
  GET_REVIEW_DETAILS: USER_MODULE + "/get-review-details/", // pass {token} for get details

  GET_HEALTH_MAP_QUESTION: USER_MODULE + "/health-map/question",
  GET_HEALTH_MAP_REPORT: USER_MODULE + "/health-map/report",
  GET_HEALTH_MAP: USER_MODULE + "/pet-health-map/",
  GET_HEALTH_MAP_SHOW_REPORT: USER_MODULE + "/health-map/show-report",
  HEALTH_MAP_DOCUMENT: USER_MODULE + "/files/pet_document/",
  GET_UPLOADES_PET_DOCUMENT: USER_MODULE + "/pet-health-map/list/",
  GET_PROFILE_IMAGE: USER_MODULE + "/health-map/pet-profile-image",

  PRODUCT_PURCHASE_HISTORY: USER_MODULE + "/product-purchase-history",
  PRODUCT_PURCHASE_HISTORY_ADD: USER_MODULE + "/product-purchase-history/add",

  GET_PRODUCT_SLIDER_LIST: USER_MODULE + "/slider-products",

  GET_PAYMENT_RECOVERY_DETAIL: USER_MODULE + "/recovery/", // {{user_id}}
  GET_SCHEDULE_PAYMENT_DETAIL: USER_MODULE + "/schedule_payment/detail",
  MAKE_SCHEDULE_PAYMENT_CHARGE: USER_MODULE + "/schedule_payment/charge",
  GET_SCHEDULE_PAYMENT_TRANSACTIONS:
    USER_MODULE + "/schedule_payment/transactions/", //	{{recovery_payment_scheduler_id}}

  GET_RECOVERY_PAYMENT_CARD_LIST: USER_MODULE + "/recovery/cards",
  ADD_RECOVERY_PAYMENT_CARD: USER_MODULE + "/recovery/add-card",

  GET_SCHEDULE_PAYMENT_CHANGE_CARD: USER_MODULE + "/schedule_payment/", // {{recovery_payment_scheduler_id}}
  SCHEDULE_PAYMENT_CHANGE_CARD: USER_MODULE + "/schedule_payment/change-card",

  CHANGE_GROOMER_TIP: USER_MODULE + "/charge-tip",

  LIST_OF_HOLIDAY_CALENDER: USER_MODULE + "/list-of-holiday-for-calendar",

  //addon Price calculation

  addonPriceCalculation: USER_MODULE + "/addon-price-calculate",
  clinicTiming: USER_MODULE + "/clinic-timing",
  DEFAULT_CARD: USER_MODULE + "/checkout/set-default-card",
};
