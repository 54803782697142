import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map } from "rxjs/operators";

import { CookieService } from "ngx-cookie";
import { ToastrService } from "ngx-toastr";
import { COOKIE_PREFIX } from "src/environments/environment";
import { Globals } from "../../globals";
import { User } from "../../models/user.model";
import { CartsService } from "./carts.service";
import { UserService } from "./user.service";

declare var $: any;
// operators

@Directive()
@Injectable()
export class AuthService {
  loggedIn = false;
  isAdmin = false;
  loggedInRole = "";
  jwtHelper = new JwtHelperService();
  currentUser: User = new User();

  @Output() localToken = new EventEmitter();
  @Output() loggedInUser = new EventEmitter();

  constructor(
    private userService: UserService,
    private globals: Globals,
    private router: Router,
    private toasterService: ToastrService,
    public cookieService: CookieService,
    public cartsService: CartsService
  ) {
    this.toasterService = toasterService;
    this.manageSession();

    const sessionStorage_transfer = function (event) {
      if (!event) {
        event = window.event;
      } // ie suq
      if (!event.newValue) {
        return;
      } // do nothing if no value to work with
      if (event.key == "getSessionStorage") {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
      } else if (event.key == "sessionStorage" && !sessionStorage.length) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            sessionStorage.setItem(key, data[key]);
          }
        }
      }
    };

    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
      (<any>window).attachEvent("onstorage", sessionStorage_transfer);
    }

    if (!sessionStorage.length) {
      localStorage.setItem("getSessionStorage", "foobar");
      localStorage.removeItem("getSessionStorage");
    }

    function switchBackEvent(storageEvent) {
      if (storageEvent.key == "switch" && storageEvent.newValue == "false") {
        localStorage.removeItem("switch");
        setTimeout(function () {
          location.replace("/admin");
        }, 300);
      }
    }
  }

  manageSession() {
    if (this.cookieService.get(`${COOKIE_PREFIX}_token`)) {
      const setToken = this.cookieService.get(`${COOKIE_PREFIX}_token`);
      localStorage.setItem(`${COOKIE_PREFIX}_token`, setToken);
      this.setCurrentUsers(setToken);
      const location = window.location.href;
      const path = location.split("/");
      if (path[6]) {
        this.router.navigate([
          path[3] + "/" + path[4] + "/" + path[5] + "/" + path[6],
        ]);
      } else if (path[5]) {
        this.router.navigate([path[3] + "/" + path[4] + "/" + path[5]]);
      } else if (path[4]) {
        this.router.navigate([path[3] + "/" + path[4]]);
      } else if (path[3]) {
        this.router.navigate([path[3]]);
      } else {
        this.router.navigate(["/"]);
      }
    } else {
      this.logoutUser();
    }
  }

  logoutUser() {
    this.cookieService.remove("current_user");
    this.cookieService.remove(`${COOKIE_PREFIX}_token`);
    localStorage.removeItem(`${COOKIE_PREFIX}_token`);
    this.loggedInUser.emit(false);
    this.loggedIn = false;
  }

  login(emailAndPassword) {
    return this.userService.login(emailAndPassword).pipe(
      map(
        (res) => {
          return this.maintainLogin(res.data.token);
        },
        (error) => {}
      )
    );
  }

  register(userData) {
    return this.userService.register(userData).pipe(
      map(
        (res) => {
          this.maintainLogin(res.data.token);
          return this.loggedIn;
        },
        (error) => {}
      )
    );
  }

  loginUser(token) {
    this.localToken.emit(token);
    localStorage.setItem(`${COOKIE_PREFIX}_token`, token);
    sessionStorage.setItem(`${COOKIE_PREFIX}_token`, token);
  }

  setCurrentUsers(token: any) {
    if (token) {
      this.cookieService.put(`${COOKIE_PREFIX}_token`, token);
      this.loginUser(token);
      const user = this.jwtHelper.decodeToken(token);
      this.cookieService.putObject("current_user", user);
      this.setCurrentUser(user);
    }
  }

  maintainLogin(token) {
    this.loginUser(token);
    sessionStorage.removeItem("guest_id");
    const decodedUser = this.decodeUserFromToken(
      sessionStorage.getItem(`${COOKIE_PREFIX}_token`)
    );
    this.setCurrentUser(decodedUser);
    this.setCurrentUsers(sessionStorage.getItem(`${COOKIE_PREFIX}_token`));
    this.cartsService.getCartWithApi();
    return this.loggedIn;
  }

  toastMessages(res, emailAndPassword) {
    if (res.code == "401") {
      this.toasterService[res.type](res.message);
    }
    if (res.code == "500") {
      this.toasterService[res.type](res.message);
    }
    if (
      emailAndPassword["remember_me"] !== undefined &&
      emailAndPassword["remember_me"]
    ) {
      localStorage.setItem("remember_me", res.data.token);
    }
  }

  logout() {
    sessionStorage.removeItem(`${COOKIE_PREFIX}_token`);
    localStorage.removeItem("remember_me");
    this.loggedInUser.emit(false);
    this.loggedIn = false;
    this.isAdmin = false;
    this.currentUser = new User();
    this.router.navigate(["/login"]);
  }

  decodeUserFromToken(token) {
    return this.jwtHelper.decodeToken(token);
  }

  updateUserFromToken(token) {
    sessionStorage.setItem(`${COOKIE_PREFIX}_token`, token);
    this.setCurrentUser(this.decodeUserFromToken(token));
  }

  setCurrentUser(decodedUser) {
    if (decodedUser) {
      sessionStorage.removeItem("guest_id");
    }
    this.loggedInUser.emit(true);
    this.loggedIn = true;
    this.currentUser = decodedUser;
    this.globals.currentUser = this.currentUser;
    this.globals.currentUser.role = this.currentUser.role;
    if (typeof decodedUser.switch !== "undefined" && decodedUser.switch) {
      if (decodedUser.switch_back) {
        this.globals.currentUser.switch_back = decodedUser.switch_back;
        this.globals.currentUser.switch = decodedUser.switch;
        localStorage.setItem("switch", "true");
      }
    }
  }
}
